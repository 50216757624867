"use client";
import { useStore } from "@nanostores/react";
import { $ads } from "stores/ads";
import type { Price, Product } from "api/products/types";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { resolver as adsResolver } from "api/ads";
import { gtmEvent, gtmPurchaseEvent } from "utils/gtm";
import type { Decision } from "api/ads/types";
import { ProductCardNew } from "../molecules/product-card-new";

const KevelReviewTile = memo(() => {
  const ads = useStore($ads);
  const { ref, inView } = useInView({ threshold: 0.5 });
  const [ad, setAd] = useState<Decision | null>(null);
  const [viewEventFired, setViewEventFired] = useState(false);
  const [impressionEventFired, setImpressionEventFired] = useState(false);
  const viewTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if ("reviewTile" in ads) {
      const currentAd = ads.reviewTile;
      if (currentAd) setAd(currentAd[0]);
    }
  }, [ads]);

  useEffect(() => {
    if (!inView || !ad || viewEventFired)
      return clearTimeout(viewTimer.current);

    const viewEvent = ad.events.find(({ id }) => id === 40);

    if (viewEvent) {
      viewTimer.current = setTimeout(async () => {
        await adsResolver.sendImpression(viewEvent.url);

        gtmEvent(`kevel_native_ad_inview`, ad);
        setViewEventFired(true);
      }, 1000);
    }
  }, [inView]);

  useEffect(() => {
    if (!inView || !ad || impressionEventFired) return;

    void adsResolver.sendImpression(ad.impressionUrl);
    gtmEvent(`kevel_native_ad_delivered`, ad);

    setImpressionEventFired(true);
  }, [ad, inView]);

  const currentAd = useMemo(() => {
    if (!ad) return;

    const data = ad.contents[0].data;
    const product = data.ctProduct as Product;
    const prices = data.ctPrices as Price[];
    let priceLowest = product.priceLowest;

    if (data.ctShopId) {
      const shopPrice = prices.find(
        (price) => price.shopId === Number(data.ctShopId)
      );

      priceLowest = shopPrice
        ? {
            shopId: shopPrice.shopId,
            shopName: shopPrice.shop.name,
            amount: shopPrice.amount,
            link: shopPrice.link,
            id: shopPrice.ean,
            cpcRate: Number(data.ctCpcValue || "1.5"),
            affiliate: shopPrice.shop.affiliate,
            ranking: shopPrice.ranking,
            shopType: shopPrice.shop.type,
          }
        : null;
    }

    if (!data.ctShopId && prices.length > 0)
      priceLowest = {
        shopId: prices[0].shopId,
        shopName: prices[0].shop.name,
        amount: prices[0].amount,
        link: prices[0].link,
        id: prices[0].ean,
        cpcRate: Number(data.ctCpcValue || "1.5"),
        affiliate: prices[0].shop.affiliate,
        ranking: prices[0].ranking,
        shopType: prices[0].shop.type,
      };

    const handleClickout = async () => {
      const purchaseData = {
        value: Number(data.ctCpcValue || 1),
        webshop: priceLowest?.shopName,
        position: "review tile",
        product_brand: product.brand,
        product_id: product.kieskeurigId,
        title: product.title,
        priceMean: product.averagePrice,
        ranking: priceLowest?.ranking,
        affiliate: priceLowest?.affiliate,
      };

      gtmEvent("kevel_native_ad_clicked", { ...purchaseData, ad });
    };

    return (
      <div ref={ref} className="hidden lg:block text-center">
        <h3 className="mb-1">Uitgelichte producten</h3>
        <ProductCardNew
          key={product.kisId}
          {...product}
          slug={ad.clickUrl}
          type="product"
          thumbnail={product.media[0]}
          className="h-auto w-[75%] mx-auto"
          specificationSpotlight={undefined}
          buttonText="Bekijk product"
          priceLowest={priceLowest}
          reviewCount={0}
          testPanelScore={
            product.certificates.find(
              (certificate) => certificate.certificateType === "TEST_PANEL"
            )?.score as number | undefined
          }
          onClickout={handleClickout}
          sponsored
        />
      </div>
    );
  }, [ad]);

  return currentAd;
});

KevelReviewTile.displayName = "KevelReviewTile";

export { KevelReviewTile };
